import { Dispatch, useEffect, useRef } from "react"
import {
  Animated,
  Easing,
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
} from "react-native"
import type { Card } from "../../lib/cards"
import { getCardImageFilename } from "../../lib/cards"
import { GameReducerParams } from "../../../appReducer"

interface CardProps {
  card: Card
  dispatch: Dispatch<GameReducerParams>
  isDraw: boolean
  onClick?: () => void
  style: StyleProp<ImageStyle>
}
export function CardComponent({
  card,
  dispatch,
  isDraw,
  onClick,
  style,
}: CardProps) {
  const selectedAnim = useRef(new Animated.Value(0)).current
  const positionAnim = useRef(new Animated.Value(0)).current
  const spinAnim = useRef(new Animated.Value(0)).current
  const drawAnim = useRef(new Animated.Value(0)).current
  const isDrawing = useRef(isDraw)

  const updateHandAfterAnimation = () => {
    dispatch({ type: "REMOVE_DISCARDED_FROM_HAND" })
  }

  useEffect(() => {
    Animated.timing(drawAnim, {
      toValue: 10,
      duration: 120,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start(() => (isDrawing.current = false))
  }, [])

  useEffect(() => {
    if (card.isDiscarded) {
      Animated.parallel([
        Animated.timing(positionAnim, {
          toValue: 20,
          duration: 200,
          easing: Easing.linear,
          useNativeDriver: false,
        }),
        Animated.timing(spinAnim, {
          toValue: 10,
          duration: 200,
          easing: Easing.linear,
          useNativeDriver: false,
        }),
      ]).start(updateHandAfterAnimation)
    }
  }, [card.isDiscarded])

  useEffect(() => {
    if (card.isSelected) {
      Animated.timing(selectedAnim, {
        toValue: 2,
        duration: 40,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(selectedAnim, {
        toValue: 0,
        duration: 40,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start()
    }
  }, [card.isSelected])

  const xValDraw = drawAnim.interpolate({
    inputRange: [0, 10],
    outputRange: [-150, 0],
  })
  const yValDraw = drawAnim.interpolate({
    inputRange: [0, 10],
    outputRange: [-250, 0],
  })

  const xValDiscard = positionAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 50],
  })

  const yValDiscard = positionAnim.interpolate({
    inputRange: [0, 10],
    outputRange: [0, -400],
  })

  const spinVal = spinAnim.interpolate({
    inputRange: [0, 10],
    outputRange: ["0deg", "360deg"],
  })

  const marginVal = selectedAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 20],
  })

  return (
    <TouchableOpacity onPress={onClick}>
      <Animated.View
        style={[
          {
            transform: [
              { translateX: isDrawing.current ? xValDraw : xValDiscard },
              { translateY: isDrawing.current ? yValDraw : yValDiscard },
              { rotate: spinVal },
            ],
          },
          { marginBottom: marginVal },
        ]}
      >
        <Image
          source={require(`../../../assets/cards/${getCardImageFilename(
            card
          )}.png`)}
          style={style}
        />
      </Animated.View>
    </TouchableOpacity>
  )
}
