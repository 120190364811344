import { StyleSheet, Text, View } from "react-native"

interface ScoreProps {
  cardsRemaining: number
}

export function ScoreBoard({ cardsRemaining }: ScoreProps) {
  return (
    <View style={scoreStyles.scoreContainer}>
      <Text style={scoreStyles.scoreHeaderText}>Splinters</Text>
      <Text style={scoreStyles.scoreText}>{cardsRemaining}</Text>
    </View>
  )
}

const scoreStyles = StyleSheet.create({
  scoreContainer: {
    backgroundColor: "rgba(16,16,16,0.7)",
    borderColor: "#eee",
    borderRadius: 15,
    borderWidth: 1,
    paddingTop: 8,
    paddingBottom: 10,
    paddingLeft: 12,
    paddingRight: 12,
    width: 120,
  },
  scoreHeaderText: {
    color: "#eee",
    fontSize: 15,
    fontFamily: "Archivo",
    fontWeight: "700",
    letterSpacing: 1,
    lineHeight: 30,
    textAlign: "center",
  },
  scoreText: {
    color: "#eee",
    fontSize: 30,
    fontFamily: "Archivo",
    fontWeight: "700",
    letterSpacing: 1,
    lineHeight: 30,
    textAlign: "center",
  },
})
