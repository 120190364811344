/* eslint-disable no-case-declarations */
import { Card } from "./src/lib/cards"
import { createDeck, removeDiscardedCards, shuffle } from "./src/lib/cards"
export interface State {
  deck: Card[]
  discarded: Card[]
  endGameSeconds: number
  hand: Card[]
  isActiveGame: boolean
}

export const initialState: State = {
  deck: [],
  discarded: [],
  endGameSeconds: 0,
  hand: [],
  isActiveGame: false,
}

const CardsReducerAction = {
  DISCARD: "DISCARD",
  DISCARD_FAIL: "DISCARD_FAIL",
  DRAW: "DRAW",
  DRAW_FROM_HAND: "DRAW_FROM_HAND",
  END_GAME: "END_GAME",
  NEW_GAME: "NEW_GAME",
  REMOVE_DISCARDED_FROM_HAND: "REMOVE_DISCARDED_FROM_HAND",
  SELECT_CARD: "SELECT_CARD",
} as const

type DiscardedPayload = {
  discarded: Card[]
  hand: Card[]
}
export interface GameReducerParams {
  type: keyof typeof CardsReducerAction
  payload?: Card | DiscardedPayload | number
}

export function reducer(state: State, action: GameReducerParams): State {
  switch (action.type) {
    case "DISCARD":
      // Updates hand and discarded state (triggers the discard animation)

      const { discarded, hand } = action.payload as DiscardedPayload
      return {
        ...state,
        discarded,
        hand,
      }

    case "DISCARD_FAIL":
      // Used to re-render with same state to trigger a discard failure animation
      return state

    case "DRAW":
      if (!state.isActiveGame) return state
      // Draws N cards from the deck and appends each to the end of the player's hand

      const numberOfCardsToDraw: number = action.payload as number
      const newDeck = state.deck.slice(
        0,
        state.deck.length - numberOfCardsToDraw
      )
      const newHandAfterDraw = state.hand
        .map((card: Card) => ({ ...card, isSelected: false }))
        .concat(
          state.deck
            .slice(-1 * numberOfCardsToDraw)
            .map((card: Card) => ({ ...card, isSelected: false }))
            .reverse()
        )
      return { ...state, hand: newHandAfterDraw, deck: newDeck }
    case "DRAW_FROM_HAND":
      const firstCard = state.hand[0]
      return { ...state, hand: [...state.hand.slice(1), firstCard] }
    case "END_GAME":
      // Sets isActiveGame to false and sets endGameSeconds

      const endGameSeconds = action.payload as number
      console.log(endGameSeconds)
      return { ...state, endGameSeconds, isActiveGame: false }

    case "NEW_GAME":
      // Sets isActiveGame to true and sets initial hand and deck state

      return {
        ...state,
        deck: shuffle(createDeck()),
        hand: [],
        isActiveGame: true,
      }

    case "REMOVE_DISCARDED_FROM_HAND":
      // Cleanup function that's called after the discard animation completes

      return { ...state, hand: removeDiscardedCards(state.hand) }

    case "SELECT_CARD":
      // Flips the isSelected attribute on a card (triggers the select/deselect animation)

      const card: Card = action.payload as Card
      const index = state.hand.findIndex(
        (currentCard: Card) =>
          currentCard.suit === card.suit && currentCard.value === card.value
      )

      return {
        ...state,
        hand: state.hand.map((card, i) =>
          index === i ? { ...card, isSelected: !card.isSelected } : card
        ),
      }
  }
}
