import { Card } from "./cards"
import { getAmountOfTimeFromSeconds, singleToDoubleDigit } from "./gladney"
import axios from "axios"
import { ROOT_URL } from "./api"

export function getFirstandFourthCards(hand: Card[]) {
  return { firstCard: hand.slice(-4)[0], fourthCard: hand.slice(-1)[0] }
}

export function discardSelectedCards(hand: Card[]): [Card[], Card[]] {
  // Returns new array of cards and array of cards discarded
  const { firstCard, fourthCard } = getFirstandFourthCards(hand)
  if (firstCard.suit === fourthCard.suit) {
    const validIndexesToRemove = [hand.length - 2, hand.length - 3]
    const handAfter = hand.map((card: Card, i: number) => ({
      ...card,
      isDiscarded:
        card.isSelected && validIndexesToRemove.includes(i) ? true : false,
    }))
    return [handAfter, handAfter.filter((card: Card) => card.isDiscarded)]
  } else if (firstCard.value === fourthCard.value) {
    const validIndexesToRemove = [
      hand.length - 1,
      hand.length - 2,
      hand.length - 3,
      hand.length - 4,
    ]
    const handAfter = hand.map((card: Card, i: number) => ({
      ...card,
      isDiscarded:
        card.isSelected && validIndexesToRemove.includes(i) ? true : false,
    }))

    return [handAfter, handAfter.filter((card: Card) => card.isDiscarded)]
  } else {
    return [hand, []]
  }
}

export type Score = {
  id: number
  seconds: number
  cards_remaining: number
  player_name: string
  ranking: bigint
}

export async function getLeaderboard() {
  return (await axios.get(`${ROOT_URL}/v1/scores/leaderboard`)).data
}

export function getTimeString(numberOfSeconds: number): string {
  const { seconds, minutes, hours } =
    getAmountOfTimeFromSeconds(numberOfSeconds)
  const displayHours = `${hours > 0 ? `${singleToDoubleDigit(hours)}:` : ""}`
  const displayMinutes = `${
    hours > 0 ? `${singleToDoubleDigit(minutes)}:` : `${minutes}:`
  }`
  return `${displayHours}${displayMinutes}${singleToDoubleDigit(seconds)}`
}

export async function getScoreRank(scoreId: string): Promise<string> {
  return (
    await axios.get(`${ROOT_URL}/v1/scores/ranking?id=${Number(scoreId)}`)
  ).data.ranking
}

export function updateScorePlayerName(id: number | string, name: string) {
  return axios.put(`${ROOT_URL}/v1/scores/${id}`, {
    player_name: name,
  })
}

export function sendContactForm(from: string, message: string) {
  return axios.post(`${ROOT_URL}/v1/contact`, {
    from,
    text: message,
  })
}
