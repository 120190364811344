import { Dispatch, SetStateAction } from "react"
import { StyleSheet, Text, TouchableOpacity, View } from "react-native"

interface WelcomeProps {
  startNewGame: () => void
  setIsHowToPlayModal: Dispatch<SetStateAction<boolean>>
}
export function Welcome({ setIsHowToPlayModal, startNewGame }: WelcomeProps) {
  return (
    <>
      <View style={welcomeStyles.welcomeContainer}>
        <Text style={[welcomeStyles.welcomeHeaderText, { marginBottom: 20 }]}>
          Can you beat the best?
        </Text>
        <Text style={welcomeStyles.welcomeText}>
          2x4 is the new popular single person card game that takes minutes to
          learn and provides hours of fun. Race the clock to get rid of as many
          splinters as you can! Compare your score with others around the world.
        </Text>
        <TouchableOpacity onPress={() => setIsHowToPlayModal(true)}>
          <Text style={[welcomeStyles.linkText, { marginTop: 20 }]}>
            How to Play
          </Text>
        </TouchableOpacity>
      </View>
      <View style={welcomeStyles.homeScreenButtonsContainer}>
        <TouchableOpacity onPress={startNewGame} style={welcomeStyles.button}>
          <Text style={welcomeStyles.buttonText}>New Game</Text>
        </TouchableOpacity>
        {/* <TouchableOpacity
          onPress={() => setIsHowToPlayModal(true)}
          style={welcomeStyles.button}
        >
          <Text style={welcomeStyles.buttonText}>Leaderboard</Text>
        </TouchableOpacity> */}
        {/* <Text style={[welcomeStyles.footerText, { marginTop: 10 }]}>
          ⓒ 2023 Gladney Labs
        </Text> */}
      </View>
    </>
  )
}

const welcomeStyles = StyleSheet.create({
  button: {
    backgroundColor: "rgb(16,16,16)",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    color: "#eee",
    height: 70,
    paddingTop: 23,
    paddingBottom: 23,
    paddingLeft: 40,
    paddingRight: 40,
    textAlign: "center",
    width: 275,
  },
  bottomButtonsContainer: {
    // marginTop: 6,
  },
  buttonText: {
    color: "#fefefe",
    fontFamily: "Archivo",
    fontSize: 22,
    fontWeight: "bold",
  },
  homeScreenButtonsContainer: {
    marginTop: 20,
    gap: 20,
  },
  footerText: {
    color: "#777",
    fontFamily: "Poppins",
    fontSize: 14,
    textAlign: "center",
  },
  linkText: {
    color: "#eee",
    cursor: "pointer",
    fontFamily: "Poppins",
    fontSize: 24,
    textAlign: "center",
    textDecorationLine: "underline",
  },
  welcomeContainer: {
    // backgroundColor: "rgba(16,16,16,0.7)",
    borderRadius: 10,
    marginTop: 30,
    width: 360,
  },
  welcomeHeaderText: {
    color: "#eee",
    fontFamily: "PoppinsBold",
    fontSize: 52,
    fontWeight: "900",
    textAlign: "center",
  },
  welcomeText: {
    color: "#eee",
    fontSize: 16,
    fontFamily: "Poppins",
    textAlign: "center",
  },
})
