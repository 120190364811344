import { Dispatch, useContext, useEffect, useRef, useState } from "react"
import {
  Animated,
  Easing,
  StyleSheet,
  Text,
  TouchableOpacity,
} from "react-native"
import { Card } from "../../lib/cards"
import { discardSelectedCards } from "../../lib/rules"
import { GameReducerParams } from "../../../appReducer"
import { Sound } from "expo-av/build/Audio"
import { Audio } from "expo-av"
// eslint-disable-next-line @typescript-eslint/no-var-requires
const DiscardSound = require("../../../assets/discard.wav")
// eslint-disable-next-line @typescript-eslint/no-var-requires
const DiscardFourSound = require("../../../assets/discard4.wav")
import { soundContext } from "../../context/soundContext"

interface DiscardButtonProps {
  dispatch: Dispatch<GameReducerParams>
  hand: Card[]
}

let discardSound: Sound
let discardFourSound: Sound
Audio.Sound.createAsync(DiscardSound).then(
  ({ sound: loadedSound }) => (discardSound = loadedSound)
)
Audio.Sound.createAsync(DiscardFourSound).then(
  ({ sound: loadedSound }) => (discardFourSound = loadedSound)
)

const AnimatedButton = Animated.createAnimatedComponent(TouchableOpacity)
const DISCARD_SUCCESS = "DISCARD_SUCCESS"
const DISCARD_FAILURE = "DISCARD_FAILURE"

export function DiscardButton({ dispatch, hand }: DiscardButtonProps) {
  const [sound, setSound] = useState<Sound>()
  const { isSoundOn } = useContext(soundContext)

  async function playSound(n: number) {
    if (!isSoundOn) return
    const sound = n === 4 ? discardFourSound : discardSound
    await sound.setVolumeAsync(0.25)
    await sound.playAsync()
  }

  useEffect(() => {
    return sound
      ? () => {
          sound.unloadAsync()
        }
      : undefined
  }, [sound])

  const shakeAnimation = useRef(new Animated.Value(0)).current
  const redAnimation = useRef(new Animated.Value(0)).current
  const greenAnimation = useRef(new Animated.Value(0)).current
  const [discardResult, setDiscardResult] = useState("")

  useEffect(() => {
    if (discardResult === DISCARD_SUCCESS) triggerDiscardSuccessAnimation()
    if (discardResult === DISCARD_FAILURE) triggerDiscardFailAnimation()
  }, [discardResult])

  const shakeInterpolated = shakeAnimation.interpolate({
    inputRange: [0, 0.5, 1, 1.5, 2, 2.5, 3],
    outputRange: [0, -15, 0, 15, 0, -15, 0],
  })
  const redInterpolated = redAnimation.interpolate({
    inputRange: [0, 1, 2],
    outputRange: ["rgb(16,16,16)", "rgb(200,15,15)", "rgb(16,16,16)"],
  })
  const greenInterpolated = greenAnimation.interpolate({
    inputRange: [0, 1, 2],
    outputRange: ["rgb(16,16,16)", "rgb(0,150,50)", "rgb(16,16,16)"],
  })

  const shakeAnimationStyle = { transform: [{ translateX: shakeInterpolated }] }
  const redAnimationStyle = { backgroundColor: redInterpolated }
  const greenAnimationStyle = { backgroundColor: greenInterpolated }
  const buttonAnimationStyle =
    discardResult === DISCARD_SUCCESS ? greenAnimationStyle : redAnimationStyle

  const resetButtonAnimationStyle = () => setDiscardResult("")

  const triggerDiscardSuccessAnimation = () => {
    greenAnimation.setValue(0)
    Animated.timing(greenAnimation, {
      duration: 2500,
      easing: Easing.bezier(0.01, 0.99, 0.45, 0.94),
      toValue: 2,
      useNativeDriver: false,
    }).start(resetButtonAnimationStyle)
  }
  const triggerDiscardFailAnimation = () => {
    shakeAnimation.setValue(0)
    redAnimation.setValue(0)
    Animated.parallel([
      Animated.timing(shakeAnimation, {
        duration: 800,
        toValue: 3,
        easing: Easing.bounce,
        useNativeDriver: false,
      }),
      Animated.timing(redAnimation, {
        duration: 1750,
        easing: Easing.bezier(0.2, 0.75, 0.45, 0.94),
        toValue: 2,
        useNativeDriver: false,
      }),
    ]).start(resetButtonAnimationStyle)
  }
  return (
    <Animated.View style={shakeAnimationStyle}>
      <AnimatedButton
        onPress={() => {
          const [newHand, discardedCards] = discardSelectedCards(hand)
          if (discardedCards.length > 0) {
            playSound(discardedCards.length)
            dispatch({
              type: "DISCARD",
              payload: { hand: newHand, discarded: discardedCards },
            })
            setDiscardResult(DISCARD_SUCCESS)
          } else {
            dispatch({ type: "DISCARD_FAIL" })
            setDiscardResult(DISCARD_FAILURE)
          }
        }}
        style={[discardButtonStyles.button, buttonAnimationStyle]}
      >
        <Text style={discardButtonStyles.buttonText}>Discard</Text>
      </AnimatedButton>
    </Animated.View>
  )
}

const discardButtonStyles = StyleSheet.create({
  button: {
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    color: "#eee",
    marginTop: 10,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  buttonText: {
    color: "#fefefe",
    fontFamily: "Archivo",
    fontSize: 18,
    fontWeight: "bold",
  },
  container: {
    marginTop: 6,
  },
})
