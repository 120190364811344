type CardSuit = "Clubs" | "Spades" | "Hearts" | "Diamonds"
type CardValue =
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "Jack"
  | "Queen"
  | "King"
  | "Ace"

export interface Card {
  isDiscarded?: boolean
  isSelected?: boolean
  suit: CardSuit
  value: CardValue
}

export function createDeck(): Card[] {
  const suits: CardSuit[] = ["Clubs", "Spades", "Hearts", "Diamonds"]
  const values: CardValue[] = [
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "Jack",
    "Queen",
    "King",
    "Ace",
  ]
  const deck: Card[] = []

  suits.forEach((suit: CardSuit) => {
    values.forEach((value: CardValue) => {
      deck.push({ suit, value })
    })
  })

  return deck
}

export function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

export function getCardImageFilename(card: Card): string {
  return `${card.value.toLowerCase()}_of_${card.suit.toLowerCase()}`
}

export function removeDiscardedCards(hand: Card[]): Card[] {
  return hand.filter((card: Card) => !card.isDiscarded)
}
