import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Animated, Easing, Image, Modal, StyleSheet, View } from "react-native"
import { useInterval } from "usehooks-ts"
import { createDeck, getCardImageFilename } from "../../lib/cards"

const deck = createDeck()

interface LoadingModalProps {
  isLoadingModal: boolean
  setIsLoadingModal: Dispatch<SetStateAction<boolean>>
}

export function LoadingModal({
  isLoadingModal,
  setIsLoadingModal,
}: LoadingModalProps) {
  const [cardIndex, setCardIndex] = useState(0)
  const [countdown, setCountdown] = useState(0)
  const textSizeAnimation = useRef(new Animated.Value(0)).current
  const textOpacityAnimation = useRef(new Animated.Value(0)).current

  useEffect(() => {
    // Reset counter whenever modal is not open
    if (!isLoadingModal) setCountdown(4)
  }, [isLoadingModal])

  useInterval(
    // Load all card images so that they are cached
    () => {
      if (cardIndex < deck.length - 1)
        setCardIndex((cardIndex) => cardIndex + 1)
    },
    isLoadingModal ? 40 : null
  )

  useInterval(
    () => {
      if (countdown > 1) setCountdown((countdown) => countdown - 1)
      else setIsLoadingModal(false)
    },
    isLoadingModal ? 1000 : null
  )

  useEffect(() => {
    triggerTextAnimation()
  }, [countdown])

  const triggerTextAnimation = () => {
    textSizeAnimation.setValue(300)
    textOpacityAnimation.setValue(1)
    Animated.parallel([
      Animated.timing(textSizeAnimation, {
        duration: 500,
        easing: Easing.linear,
        toValue: 1,
        useNativeDriver: false,
      }),
      Animated.timing(textOpacityAnimation, {
        duration: 500,
        easing: Easing.linear,
        toValue: 0,
        useNativeDriver: false,
      }),
    ]).start()
  }

  const textAnimationStyle = {
    fontSize: textSizeAnimation,
    opacity: textOpacityAnimation,
  }

  // Show nothing for 1 second before showing countdown
  const displayCountdown = countdown < 4 ? countdown : ""

  return (
    <Modal
      animationType="fade"
      onRequestClose={() => null}
      visible={isLoadingModal}
    >
      <View style={loadingModalStyles.container}>
        <Animated.Text
          style={[loadingModalStyles.headerText, textAnimationStyle]}
        >
          {displayCountdown}
        </Animated.Text>
        <Image
          source={require(`../../../assets/cards/${getCardImageFilename(
            deck[cardIndex]
          )}.png`)}
          style={{ height: 1, width: 1, opacity: 0 }}
        />
      </View>
    </Modal>
  )
}

const loadingModalStyles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: "#111",
    flex: 1,
    justifyContent: "center",
  },
  headerText: {
    color: "#eee",
    fontFamily: "Archivo",
    fontWeight: "700",
    letterSpacing: 1,
    marginBottom: 20,
  },
})
