import { useEffect, useState } from "react"
import { StyleSheet, Text, View } from "react-native"
import { Cell, Row, Table, TableWrapper } from "react-native-table-component"
import { getTimeString, Score } from "../../lib/rules"
import { getLeaderboard } from "../../lib/rules"

const headers = ["🏆", "Player", "🪵", "⏰"]
const headerWidths = [60, 180, 60, 100]

function parseScore(score: Score) {
  return [
    score.ranking,
    score.player_name,
    score.cards_remaining,
    getTimeString(score.seconds),
  ]
}

export function Leaderboard() {
  const [scores, setScores] = useState([])
  useEffect(() => {
    getLeaderboard().then((scores) => {
      setScores(scores.map((score: Score) => parseScore(score)))
    })
  }, [])

  return (
    <View style={{ marginBottom: 50 }}>
      <Text style={leaderboardStyles.headerText}>Leaderboard</Text>
      <Table style={leaderboardStyles.table}>
        <TableWrapper>
          <View style={leaderboardStyles.tableHeader}>
            {headers.map((header, i) => (
              <Cell
                data={header}
                style={{ width: headerWidths[i] }}
                textStyle={[
                  leaderboardStyles.tableHeaderText,
                  i === 1 ? { fontSize: 14 } : null, // Decrease font size for "Name" header
                ]}
              />
            ))}
          </View>
          {scores.map((data, i) => {
            const backgroundColor = i % 2 === 0 ? "#eee" : "#ccc"
            return (
              <Row
                data={data}
                style={[leaderboardStyles.tableRowStyle, { backgroundColor }]}
                textStyle={leaderboardStyles.tableRowTextStyle}
                widthArr={headerWidths}
              />
            )
          })}
        </TableWrapper>
      </Table>
    </View>
  )
}

const leaderboardStyles = StyleSheet.create({
  table: {
    marginTop: 20,
  },
  tableHeader: {
    backgroundColor: "#000",
    flexDirection: "row",
    textAlign: "center",
  },
  tableHeaderText: {
    color: "#eee",
    fontFamily: "Poppins",
    fontSize: 20,
    padding: 10,
    textAlign: "center",
  },
  tableRowStyle: {
    backgroundColor: "#eee",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  tableRowTextStyle: {
    fontFamily: "Poppins",
  },
  headerText: {
    color: "#eee",
    fontFamily: "Archivo",
    fontWeight: "700",
    fontSize: 24,
    letterSpacing: 1,
    textAlign: "center",
  },
})
