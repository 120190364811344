import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import {
  ActivityIndicator,
  Modal,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native"
import {
  getScoreRank,
  getTimeString,
  updateScorePlayerName,
} from "../../lib/rules"
import * as Clipboard from "expo-clipboard"
import axios from "axios"
import { ROOT_URL } from "../../lib/api"
import ReactGA from "react-ga"
import { sendContactForm } from "../../lib/rules"

ReactGA.initialize("UA-253764930-1")

interface GameOverModalProps {
  cardsRemaining: number
  isGameOverModal: boolean
  seconds: number
  setIsGameOverModal: Dispatch<SetStateAction<boolean>>
  startNewGame: () => void
}

export function GameOverModal({
  cardsRemaining,
  isGameOverModal,
  seconds,
  setIsGameOverModal,
  startNewGame,
}: GameOverModalProps) {
  const [worldRank, setWorldRank] = useState<string | null>(null)
  const [isScoreCopied, setIsScoreCopied] = useState(false)
  const [isNameSubmitted, setIsNameSubmitted] = useState(false)
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false)
  const [playerName, setPlayerName] = useState("")
  const scoreId = useRef(0)
  const [message, setMessage] = useState("")
  const [isFeedbackBad, setIsFeedbackBad] = useState(false)
  const [isMessageSent, setIsMessageSent] = useState(false)

  const handleNewGameClick = () => {
    setIsScoreCopied(false)
    startNewGame()
    setIsGameOverModal(false)
  }

  const handleSendClick = () => {
    sendContactForm("game feedback", message)
      .then(() => {
        setIsMessageSent(true)
      })
      .catch(() => alert("Error sending message"))
  }

  const createScoreRecordAndSetRanking = () => {
    axios
      .post(`${ROOT_URL}/v1/scores/`, {
        seconds,
        cards_remaining: cardsRemaining,
        player_name: "Anonymous",
      })
      .then(({ data: { id } }) => {
        scoreId.current = id
        getScoreRank(id).then((rank) => {
          setWorldRank(`#${rank}`)
        })
      })
      .catch(() => {
        setWorldRank("N/A")
      })
  }

  const updatePlayerName = () => {
    updateScorePlayerName(scoreId.current, playerName)
      .then(() => setIsNameSubmitted(true))
      .catch(() => alert("Error updating name."))
  }

  useEffect(() => {
    if (isGameOverModal) createScoreRecordAndSetRanking()
  }, [isGameOverModal])

  return (
    <Modal
      animationType="slide"
      onRequestClose={() => setIsGameOverModal(false)}
      visible={isGameOverModal}
    >
      <View style={gameOverModalStyles.container}>
        <Text style={gameOverModalStyles.headerText}>Game Over</Text>
        <Text style={gameOverModalStyles.statsText}>
          🪵 {cardsRemaining} ⏰ {getTimeString(seconds)} 🏆{" "}
          {worldRank || <ActivityIndicator color="#eee" size="small" />}
        </Text>
        {Number(worldRank?.substring(1)) < 100 && (
          <View
            style={{
              marginTop: 20,
              alignItems: "center",
            }}
          >
            <Text
              style={[
                gameOverModalStyles.congratsText,
                gameOverModalStyles.congratsTextBig,
              ]}
            >
              Congratulations, you are in the top 100!
            </Text>
            {!isNameSubmitted ? (
              <>
                <TextInput
                  onChangeText={(text) => setPlayerName(text)}
                  placeholder="Enter your name..."
                  placeholderTextColor={"#ccc"}
                  style={gameOverModalStyles.nameInput}
                  value={playerName}
                />
                <TouchableOpacity
                  onPress={updatePlayerName}
                  style={gameOverModalStyles.submitButton}
                >
                  <Text style={gameOverModalStyles.submitButtonText}>
                    Submit
                  </Text>
                </TouchableOpacity>
              </>
            ) : (
              <Text style={gameOverModalStyles.playerNameText}>
                {playerName}
              </Text>
            )}
          </View>
        )}
        <View style={gameOverModalStyles.buttonContainer}>
          <TouchableOpacity
            onPress={async () => {
              try {
                await Clipboard.setStringAsync(
                  `2️⃣❎4️⃣ 🪵 ${cardsRemaining} ⏰ ${getTimeString(
                    seconds
                  )} 🏆 ${worldRank || "N/A"}`
                )
                setIsScoreCopied(true)
              } catch (e) {
                alert(`Unable to copy stats to clipboard: ${e}`)
              }
            }}
            style={gameOverModalStyles.button}
          >
            <Text style={gameOverModalStyles.buttonText}>
              {isScoreCopied ? "Copied!" : "Share Results"}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={handleNewGameClick}
            style={gameOverModalStyles.button}
          >
            <Text style={gameOverModalStyles.buttonText}>New Game</Text>
          </TouchableOpacity>
        </View>
        {!isFeedbackSubmitted ? (
          <>
            <Text style={gameOverModalStyles.feedbackText}>
              Are you enjoying 2x4?
            </Text>
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-around",
                width: 150,
                marginBottom: 50,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  ReactGA.event({
                    category: "Feedback",
                    action: "Rated game",
                    value: 1,
                  })
                  setIsFeedbackSubmitted(true)
                }}
              >
                <Text style={{ fontSize: 34 }}>👍</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  ReactGA.event({
                    category: "Feedback",
                    action: "Rated game",
                    value: 2,
                  })
                  setIsFeedbackSubmitted(true)
                  setIsFeedbackBad(true)
                }}
              >
                <Text style={{ fontSize: 34, marginTop: 10 }}>👎</Text>
              </TouchableOpacity>
            </View>
          </>
        ) : (
          (!isFeedbackBad || isMessageSent) && (
            <Text style={gameOverModalStyles.feedbackText}>
              Thanks for the feedback!
            </Text>
          )
        )}
        {isFeedbackBad && !isMessageSent && (
          <>
            <Text
              style={[
                gameOverModalStyles.feedbackText,
                { textAlign: "center" },
              ]}
            >
              Tell us more...
            </Text>
            <TextInput
              multiline={true}
              numberOfLines={6}
              onChangeText={(text) => setMessage(text)}
              style={gameOverModalStyles.input}
              value={message}
            />
            <TouchableOpacity
              style={[gameOverModalStyles.button, { marginTop: 40 }]}
              onPress={handleSendClick}
            >
              <Text style={gameOverModalStyles.buttonText}>Send</Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    </Modal>
  )
}

const gameOverModalStyles = StyleSheet.create({
  button: {
    backgroundColor: "#111",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    color: "#eee",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 40,
    width: 275,
  },
  buttonContainer: {
    marginTop: 50,
    gap: 20,
  },
  buttonText: {
    color: "#eee",
    fontFamily: "Archivo",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
  congratsText: {
    color: "#eee",
    marginBottom: 20,
    fontFamily: "Poppins",
  },
  congratsTextBig: {
    fontSize: 18,
  },
  container: {
    alignItems: "center",
    backgroundColor: "#111",
    flex: 1,
    justifyContent: "center",
  },
  feedbackText: {
    color: "#eee",
    fontFamily: "Poppins",
    fontSize: 24,
    marginTop: 60,
    textAlign: "center",
  },
  headerText: {
    color: "#eee",
    fontFamily: "Archivo",
    fontSize: 40,
    fontWeight: "700",
    letterSpacing: 1,
  },
  input: {
    borderColor: "#ccc",
    color: "#eee",
    borderWidth: 1,
    fontFamily: "Poppins",
    fontSize: 16,
    outlineColor: "#000",
    padding: 10,
    width: 400,
  },
  nameInput: {
    borderBottomColor: "#ccc",
    color: "#eee",
    borderBottomWidth: 1,
    fontFamily: "Poppins",
    fontSize: 18,
    outlineColor: "#000",
    padding: 10,
    textAlign: "center",
    width: 300,
  },
  playerNameText: {
    color: "#eee",
    fontFamily: "Poppins",
    fontSize: 24,
    marginTop: 55,
    marginBottom: 55,
  },
  statsText: {
    color: "#ddd",
    fontFamily: "Archivo",
    fontSize: 30,
    marginTop: 30,
  },
  submitButton: {
    backgroundColor: "#333",
    borderColor: "#eee",
    borderRadius: 10,
    // borderWidth: 1,
    marginTop: 15,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    width: 100,
  },
  submitButtonText: {
    color: "#fefefe",
    fontFamily: "Poppins",
    fontSize: 16,
    textAlign: "center",
  },
})
