import { Dispatch, SetStateAction, useState } from "react"
import {
  Modal,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native"
import { sendContactForm } from "../../lib/rules"

interface ContactModalProps {
  isContactModal: boolean
  setIsContactModal: Dispatch<SetStateAction<boolean>>
}
export function ContactModal({
  isContactModal,
  setIsContactModal,
}: ContactModalProps) {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [isMessageSent, setIsMessageSent] = useState(false)

  const handleSendClick = () => {
    if (!message) return alert("Please enter a message first")
    sendContactForm(email, message)
      .then(() => {
        setIsMessageSent(true)
      })
      .catch(() => alert("Error sending message"))
  }

  return (
    <Modal
      animationType="fade"
      onRequestClose={() => setIsContactModal(false)}
      visible={isContactModal}
    >
      <View style={contactModalStyles.container}>
        <View style={{ alignItems: "flex-end", marginBottom: 20, width: 420 }}>
          <TouchableOpacity
            onPress={() => setIsContactModal(false)}
            style={contactModalStyles.closeButton}
          >
            <Text
              style={{
                color: "#aaa",
                fontFamily: "Poppins",
                fontSize: 28,
              }}
            >
              ⓧ
            </Text>
          </TouchableOpacity>
        </View>
        <Text style={contactModalStyles.headerText}>Contact Us</Text>
        {isMessageSent ? (
          <Text style={contactModalStyles.labelText}>Message sent!</Text>
        ) : (
          <>
            <Text style={contactModalStyles.labelText}>Your email address</Text>
            <TextInput
              onChangeText={(text) => setEmail(text)}
              style={contactModalStyles.input}
              value={email}
            />
            <Text style={contactModalStyles.labelText}>Message</Text>
            <TextInput
              multiline={true}
              numberOfLines={6}
              onChangeText={(text) => setMessage(text)}
              style={contactModalStyles.input}
              value={message}
            />
            <TouchableOpacity
              style={contactModalStyles.button}
              onPress={handleSendClick}
            >
              <Text style={contactModalStyles.buttonText}>Send</Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    </Modal>
  )
}

const contactModalStyles = StyleSheet.create({
  button: {
    backgroundColor: "#333",
    borderColor: "#eee",
    borderRadius: 10,
    // borderWidth: 1,
    marginTop: 15,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    width: 100,
  },
  buttonText: {
    color: "#fefefe",
    fontFamily: "Poppins",
    fontSize: 16,
    textAlign: "center",
  },
  closeButton: {
    cursor: "pointer",
    paddingRight: 10,
  },
  container: {
    alignItems: "center",
    backgroundColor: "#111",
    flex: 1,
    justifyContent: "flex-start",
    padding: 20,
  },
  headerText: {
    color: "#eee",
    fontFamily: "Archivo",
    fontSize: 48,
    fontWeight: "700",
    letterSpacing: 1,
    marginBottom: 20,
  },
  input: {
    borderColor: "#ccc",
    color: "#eee",
    borderWidth: 1,
    fontFamily: "Poppins",
    fontSize: 16,
    outlineColor: "#000",
    padding: 10,
    width: 400,
  },
  labelText: {
    color: "#eee",
    fontFamily: "Poppins",
    fontSize: 18,
    letterSpacing: 1,
    marginBottom: 20,
    marginTop: 20,
    maxWidth: 420,
    textAlign: "left",
  },
})
