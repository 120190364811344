/* eslint-disable @typescript-eslint/no-var-requires */
import { useContext, useEffect } from "react"
import { Image, StyleSheet, TouchableOpacity, View } from "react-native"
import { Audio } from "expo-av"
import { Sound } from "expo-av/build/Audio"
import { soundContext } from "../../context/soundContext"

const DrawSound = require("../../../assets/draw.wav")
const cardBackImage = require("../../../assets/cards/back.png")
interface DeckProps {
  cardCount: number
  onClick: () => void
}

const fullDeck = 52
const additionalSpacePerCard = 0.5
const deckHeightAdjuster = 3

let sound: Sound
Audio.Sound.createAsync(DrawSound).then(
  ({ sound: loadedSound }) => (sound = loadedSound)
)

export function Deck({ cardCount, onClick }: DeckProps) {
  const { isSoundOn } = useContext(soundContext)

  async function playSound() {
    if (!isSoundOn) return
    await sound.setVolumeAsync(0.5)
    await sound.playAsync()
  }

  useEffect(() => {
    playSound()
  }, [cardCount])

  return (
    <TouchableOpacity onPress={onClick}>
      <View
        style={[
          deckStyles.deckContainer,
          {
            marginLeft:
              125 + Math.floor(fullDeck - cardCount) * additionalSpacePerCard,
          },
        ]}
      >
        {new Array(Math.floor(cardCount / deckHeightAdjuster) || 1)
          .fill("")
          .map((_, i) => (
            <View key={`deck-card-${i}`} style={deckStyles.cardContainer}>
              <Image source={cardBackImage} style={deckStyles.card} />
            </View>
          ))}
      </View>
    </TouchableOpacity>
  )
}

const deckStyles = StyleSheet.create({
  card: {
    height: 181,
    width: 125,
  },
  cardContainer: {
    backgroundColor: "#fff",
    borderColor: "#999",
    borderRadius: 5,
    borderWidth: 1,
    cursor: "pointer",
    marginLeft: -131,
    paddingLeft: 3,
    paddingRight: 3,
  },
  deckContainer: {
    flexDirection: "row",
  },
})
