import { Dispatch } from "react"
import { StyleSheet, Text, TouchableOpacity } from "react-native"
import { GameReducerParams } from "../../../appReducer"

interface EndGameButtonProps {
  dispatch: Dispatch<GameReducerParams>
  isActiveGame: boolean
}
export function EndGameButton({ dispatch, isActiveGame }: EndGameButtonProps) {
  return (
    <TouchableOpacity
      onPress={() => dispatch({ type: "END_GAME" })}
      style={[
        endGameButtonStyles.button,
        isActiveGame
          ? endGameButtonStyles.button_enabled
          : endGameButtonStyles.button_disabled,
      ]}
    >
      <Text
        style={[
          endGameButtonStyles.buttonText,
          isActiveGame
            ? endGameButtonStyles.buttonText_enabled
            : endGameButtonStyles.buttonText_disabled,
        ]}
      >
        {"End\nGame"}
      </Text>
    </TouchableOpacity>
  )
}

const endGameButtonStyles = StyleSheet.create({
  button: {
    backgroundColor: "rgba(16,16,16,0.7)",
    borderRadius: 15,
    borderWidth: 1,
    color: "#eee",
    padding: 20,
  },
  button_enabled: {
    borderColor: "#eee",
  },
  button_disabled: {
    borderColor: "#999",
  },
  buttonText: {
    fontFamily: "Archivo",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonText_disabled: {
    color: "#777",
  },
  buttonText_enabled: {
    color: "#fefefe",
  },
  container: {
    marginTop: 6,
  },
})
