import React, { Fragment, useEffect, useReducer, useState } from "react"
import {
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native"
import { useFonts } from "expo-font"
import { useIsFirstRender } from "usehooks-ts"
import "@expo/match-media"
import { useMediaQuery } from "react-responsive"

import { Deck } from "./src/components/Deck/Deck"
import { Discarded } from "./src/components/Discarded/Discarded"
import { DiscardButton } from "./src/components/DiscardButton/DiscardButton"
import { EndGameButton } from "./src/components/EndGameButton/EndGameButton"
import { GameOverModal } from "./src/components/GameOverModal/GameOverModal"
import { Hand } from "./src/components/Hand/Hand"
import { ScoreBoard } from "./src/components/Score/Score"
import { Timer } from "./src/components/Timer/Timer"
import { Leaderboard } from "./src/components/Leaderboard/Leaderboard"

import { screenSizeContext } from "./src/context/screenSizeContext"
import { soundContext } from "./src/context/soundContext"

import { reducer, initialState } from "./appReducer"

import ReactGA from "react-ga"
import { LoadingModal } from "./src/components/LoadingModal/LoadingModal"
import { HowToPlayModal } from "./src/components/HowToPlayModal/HowToPlayModal"
import { ContactModal } from "./src/components/ContactModal/ContactModal"
import { Welcome } from "./src/components/Welcome/Welcome"
import { Adsense } from "@ctrl/react-adsense"
import { Audio } from "expo-av"
import type { Sound } from "expo-av/build/Audio"
// eslint-disable-next-line @typescript-eslint/no-var-requires
const DrawSound = require("./assets/draw.wav")

ReactGA.initialize("UA-253764930-1")
ReactGA.pageview("index")

let sound: Sound
Audio.Sound.createAsync(DrawSound).then(
  ({ sound: loadedSound }) => (sound = loadedSound)
)

export default function App() {
  useFonts({
    Archivo: require("./assets/Archivo_Black/ArchivoBlack-Regular.ttf"),
    Azeret: require("./assets/Azeret_Mono/AzeretMono-VariableFont_wght.ttf"),
    Poppins: require("./assets/Poppins/Poppins-Regular.ttf"),
    PoppinsBold: require("./assets/Poppins/Poppins-Bold.ttf"),
  })

  const [state, dispatch] = useReducer(reducer, initialState)
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false)
  const [isGameOverModal, setIsGameOverModal] = useState<boolean>(false)
  const [isHowToPlayModal, setIsHowToPlayModal] = useState<boolean>(false)
  const [isContactModal, setIsContactModal] = useState<boolean>(false)
  const [isSoundOn, setIsSoundOn] = useState(true)
  const isFirstRender: boolean = useIsFirstRender()
  const cardsRemaining: number = state.deck.length + state.hand.length
  const isDeckEmpty = state.deck.length === 0
  const isTabletOrMobileDevice: boolean = useMediaQuery({
    maxDeviceWidth: 1224,
  })

  async function playSound() {
    if (!isSoundOn) return
    await sound.setVolumeAsync(0.5)
    await sound.playAsync()
  }

  useEffect(() => {
    if (state.isActiveGame && cardsRemaining === 0) {
      dispatch({ type: "END_GAME" })
    }
  }, [state.hand])

  useEffect(() => {
    if (state.isActiveGame) {
      dispatch({ type: "DRAW", payload: 4 }) // Start the game by drawing four cards automatically
    } else if (!isFirstRender) {
      ReactGA.modalview("game_over")
      setIsGameOverModal(true)
    }
  }, [state.isActiveGame])

  useEffect(() => {
    if (isHowToPlayModal) ReactGA.modalview("how_to_play")
  }, [isHowToPlayModal])

  useEffect(() => {
    if (!isLoadingModal && !isFirstRender) {
      setTimeout(() => dispatch({ type: "NEW_GAME" }), 200)
    }
  }, [isLoadingModal])

  const startNewGame = () => {
    ReactGA.modalview("new_game")
    setIsLoadingModal(true)
  }

  return (
    <screenSizeContext.Provider value={{ isTabletOrMobileDevice }}>
      <soundContext.Provider value={{ isSoundOn }}>
        <ImageBackground
          resizeMode="cover"
          source={require("./assets/felt.jpeg")}
          style={{ flex: 1 }}
        >
          <View style={styles.container}>
            {state.isActiveGame && (
              <Adsense
                client="ca-pub-4523449995712018"
                slot="1943183193"
                style={{ width: "100%", height: "50px", maxWidth: "500px" }}
                format="fixed"
              />
            )}
            <View style={styles.logoContainer}>
              {!state.isActiveGame && (
                <Image
                  resizeMode="cover"
                  source={require("./assets/logo-horizontal.png")}
                  style={{ height: 75, width: 195 }}
                />
              )}
            </View>
            <View style={styles.scoreAndTimerContainer}>
              <ScoreBoard cardsRemaining={cardsRemaining} />
              <Timer isActiveGame={state.isActiveGame} dispatch={dispatch} />
              <EndGameButton
                dispatch={dispatch}
                isActiveGame={state.isActiveGame}
              />
            </View>
            {!state.isActiveGame && !isGameOverModal && (
              <Welcome
                setIsHowToPlayModal={setIsHowToPlayModal}
                startNewGame={startNewGame}
              />
            )}
            {state.isActiveGame && (
              <Fragment>
                <View style={styles.deckAndDiscardedContainer}>
                  {!isDeckEmpty && (
                    <Deck
                      cardCount={state.deck.length}
                      onClick={() => dispatch({ type: "DRAW", payload: 1 })}
                    />
                  )}
                  {isDeckEmpty && (
                    <View style={styles.drawFromHandContainer}>
                      <TouchableOpacity
                        onPress={() => {
                          playSound()
                          dispatch({ type: "DRAW_FROM_HAND" })
                        }}
                        style={{ alignItems: "center" }}
                      >
                        <Image
                          resizeMode="cover"
                          source={require("./assets/arrow.png")}
                          style={{ height: 50, marginBottom: 5, width: 50 }}
                        />
                        <Text style={styles.drawFromHandText}>Draw</Text>
                        <Text style={styles.drawFromHandText}>from</Text>
                        <Text style={styles.drawFromHandText}>hand</Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  <Discarded cards={state.discarded} />
                </View>
                <Hand cards={state.hand} dispatch={dispatch} />
                <View style={styles.bottomButtonsContainer}>
                  <DiscardButton dispatch={dispatch} hand={state.hand} />
                </View>
              </Fragment>
            )}
            {!state.isActiveGame && (
              <View style={{ marginTop: 80 }}>
                <Leaderboard />
              </View>
            )}
            {state.isActiveGame && (
              <TouchableOpacity onPress={() => setIsSoundOn(!isSoundOn)}>
                <Text
                  style={[
                    styles.linkText,
                    {
                      textDecorationLine: "none",
                      marginBottom: 20,
                      marginTop: 20,
                    },
                  ]}
                >
                  Sound: {isSoundOn ? "ON" : "OFF"}
                </Text>
              </TouchableOpacity>
            )}
            <TouchableOpacity onPress={() => setIsContactModal(true)}>
              <Text style={[styles.linkText, { marginBottom: 80 }]}>
                Contact Us
              </Text>
            </TouchableOpacity>
          </View>
        </ImageBackground>
        <GameOverModal
          cardsRemaining={cardsRemaining}
          isGameOverModal={isGameOverModal}
          seconds={state.endGameSeconds}
          setIsGameOverModal={setIsGameOverModal}
          startNewGame={startNewGame}
        />
        <LoadingModal
          isLoadingModal={isLoadingModal}
          setIsLoadingModal={setIsLoadingModal}
        />
        <HowToPlayModal
          isHowToPlayModal={isHowToPlayModal}
          setIsHowToPlayModal={setIsHowToPlayModal}
        />
        <ContactModal
          isContactModal={isContactModal}
          setIsContactModal={setIsContactModal}
        />
      </soundContext.Provider>
    </screenSizeContext.Provider>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: "rgb(16,16,16)",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    color: "#eee",
    height: 70,
    paddingTop: 23,
    paddingBottom: 23,
    paddingLeft: 40,
    paddingRight: 40,
    textAlign: "center",
    width: 275,
  },
  bottomButtonsContainer: {
    // marginTop: 6,
  },
  buttonText: {
    color: "#fefefe",
    fontFamily: "Archivo",
    fontSize: 22,
    fontWeight: "bold",
  },
  container: {
    alignItems: "center",
    flex: 1,
    flexWrap: "wrap-reverse",
    gap: 10,
    justifyContent: "flex-start",
    width: "101%",
  },
  deckAndDiscardedContainer: {
    flexDirection: "row",
    maxWidth: 420,
  },
  drawFromHandContainer: {
    backgroundColor: "#161616",
    borderRadius: 10,
    justifyContent: "center",
    minHeight: 181,
    minWidth: 125,
    marginRight: 29,
  },
  drawFromHandText: {
    color: "#fefefe",
    fontFamily: "Archivo",
    fontSize: 22,
    fontWeight: "bold",
    maxWidth: 100,
    textAlign: "center",
  },
  linkText: {
    color: "#eee",
    cursor: "pointer",
    fontFamily: "Poppins",
    fontSize: 20,
    textAlign: "center",
    textDecorationLine: "underline",
  },
  logoContainer: {
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    shadowColor: "#020",
    shadowOffset: { width: -2, height: 2 },
    shadowRadius: 3,
    width: "100%",
  },
  scoreAndTimerContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    maxWidth: 420,
    width: "100%",
  },
})
