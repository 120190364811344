import { Dispatch, useEffect, useState } from "react"
import { StyleSheet, Text, View } from "react-native"
import { getTimeString } from "../../lib/rules"
import { GameReducerParams } from "../../../appReducer"
import { useInterval } from "usehooks-ts"

interface TimerProps {
  isActiveGame: boolean
  dispatch: Dispatch<GameReducerParams>
}

export function Timer({ dispatch, isActiveGame }: TimerProps) {
  const [seconds, setSeconds] = useState(0)

  useInterval(
    () => {
      setSeconds((seconds) => seconds + 1)
    },
    isActiveGame ? 1000 : null
  )

  useEffect(() => {
    if (isActiveGame) setSeconds(0)
    else dispatch({ type: "END_GAME", payload: seconds })
  }, [isActiveGame])

  return (
    <View style={timerStyles.timerContainer}>
      <Text style={timerStyles.timerText}>{getTimeString(seconds)}</Text>
    </View>
  )
}

const timerStyles = StyleSheet.create({
  timerContainer: {
    backgroundColor: "rgba(16,16,16,0.7)",
    borderColor: "#eee",
    borderRadius: 15,
    borderWidth: 1,
    justifyContent: "center",
    padding: 12,
    textAlign: "center",
    width: 140,
  },
  timerText: {
    color: "#eee",
    flex: 1,
    fontSize: 28,
    fontFamily: "Azeret",
    fontWeight: "700",
    height: 25,
    marginTop: 12,
  },
})
