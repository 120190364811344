import { Dispatch, useContext, useEffect, useState } from "react"
import { Card } from "../../lib/cards"
import { StyleSheet, View } from "react-native"
import { CardComponent } from "../Card/CardComponent"
import { GameReducerParams } from "../../../appReducer"
import { Sound } from "expo-av/build/Audio"
import { Audio } from "expo-av"
import { soundContext } from "../../context/soundContext"
// eslint-disable-next-line @typescript-eslint/no-var-requires
const PickSound = require("../../../assets/pick2.wav")

interface HandProps {
  cards: Card[]
  dispatch: Dispatch<GameReducerParams>
}

let sound: Sound
Audio.Sound.createAsync(PickSound).then(
  ({ sound: loadedSound }) => (sound = loadedSound)
)

export function Hand({ cards, dispatch }: HandProps) {
  const { isSoundOn } = useContext(soundContext)

  async function playSound() {
    if (!isSoundOn) return
    await sound.setVolumeAsync(0.5)
    await sound.playAsync()
  }

  return (
    <View
      style={[
        handStyles.handContainer,
        cards.length > 4
          ? handStyles.handContainer_moreThan4Cards
          : handStyles.handContainer_equalToOrlessThan4Cards,
      ]}
    >
      {cards.length > 4 &&
        cards
          .slice(-8, -4)
          .map((card, i) => (
            <CardComponent
              card={card}
              dispatch={dispatch}
              isDraw={false}
              key={`hand-extra-${i}`}
              style={handStyles.extraCard}
            />
          ))}
      {cards.slice(-4).map((card, i) => {
        const isLastCardWithLessThanFourInHand = cards.length - 1 === i
        const isLastCardWithFourOrMoreInHand = i === 3
        return (
          <CardComponent
            card={card}
            dispatch={dispatch}
            isDraw={
              isLastCardWithLessThanFourInHand || isLastCardWithFourOrMoreInHand
            }
            key={`hand-${card.value}-${card.suit}`}
            onClick={() => {
              playSound()
              dispatch({ type: "SELECT_CARD", payload: card })
            }}
            style={handStyles.card}
          />
        )
      })}
    </View>
  )
}

const handStyles = StyleSheet.create({
  card: {
    height: 217,
    marginLeft: -95,
    width: 150,
  },
  handContainer: {
    alignItems: "flex-end",
    flexDirection: "row",
    flexWrap: "wrap",
    height: 260,
    maxWidth: 350,
  },
  handContainer_equalToOrlessThan4Cards: {
    marginLeft: 100,
  },
  handContainer_moreThan4Cards: {
    marginLeft: 145,
  },
  extraCard: {
    height: 217,
    marginLeft: -148,
    width: 150,
  },
})
