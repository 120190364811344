// NUMBERS

export function toFixedNumber(n: number, decimalPlaces: number) {
  return decimalPlaces ? Number(Number(n).toFixed(decimalPlaces)) : Number(n)
}

export function singleToDoubleDigit(n: number): string {
  return `0${n}`.slice(-2)
}

export interface TimeOutput {
  years: number
  months: number
  weeks: number
  days: number
  hours: number
  minutes: number
  seconds: number
}

export function getAmountOfTimeFromSeconds(seconds: number): TimeOutput {
  const secondsInAMinute = 60
  const secondsInAnHour = 3600
  const secondsInADay = 86400
  const secondsInAWeek = 604800
  const secondsInAMonth = 2592000 // Assumes 30 day month
  const secondsInAYear = 31557600
  return {
    years: Math.floor(seconds / secondsInAYear),
    months: Math.floor((seconds % secondsInAYear) / secondsInAMonth),
    weeks: Math.floor((seconds % secondsInAMonth) / secondsInAWeek),
    days: Math.floor((seconds % secondsInAWeek) / secondsInADay),
    hours: Math.floor((seconds % secondsInADay) / secondsInAnHour),
    minutes: Math.floor((seconds % secondsInAnHour) / secondsInAMinute),
    seconds: seconds % secondsInAMinute,
  }
}

export function countdownUntil(date: Date): TimeOutput {
  const diffInSeconds = Math.floor(
    (new Date(date).getTime() - Date.now()) / 1000
  )
  return getAmountOfTimeFromSeconds(diffInSeconds)
}

// STRINGS

export function lowerCaseNoSpaces(str: string) {
  return String(str).toLowerCase().replace(/ /g, "")
}

export function truncateString(
  str: string,
  lengthLimit: number,
  ending = "...",
  reverse?: boolean /* ...this */
) {
  return str.length > lengthLimit
    ? reverse
      ? `${ending}${str.substring(str.length - lengthLimit)}`
      : `${str.substring(0, lengthLimit)}${ending}`
    : str
}

// ARRAYS

export function getArrayOfRollingSum(arr: number[], decimalPlaces: number) {
  return arr.reduce(
    (acc, i, index) =>
      index > 0
        ? [
            ...acc,
            toFixedNumber(acc[acc.length - 1] + Number(i), decimalPlaces),
          ]
        : [i],
    [] as number[]
  )
}

export function groupObjectsByKeyValue<T extends object, U extends keyof T>(
  arr: T[],
  key: U
) {
  const result: { [key: string]: T[] } = {}
  arr.forEach((obj: T) => {
    const keyValue = String(obj[key])
    if (result[keyValue]) result[keyValue].push(obj)
    else result[keyValue] = [obj]
  })
  return result
}

// SEQUELIZE

// export function convertQueryParamOperators(params: any) {
//   const output:Record<string,string> = {}
//   for (const param in params) {
//     const paramString = String(param)
//     const operator = paramString.substring(paramString.length - 1)
//     const paramStringWithoutOperator = paramString.substring(
//       0,
//       paramString.length - 1
//     )
//     switch (operator) {
//       case "!":
//         output[paramStringWithoutOperator] = { [Op.ne]: params[param] }
//         break
//       default:
//         output[paramString] = params[param]
//     }
//   }
//   return output
// }

// MISC

type GenericFunction = (...args: unknown[]) => unknown
export function pipe<T>(
  ...funcs: [
    firstFunc: GenericFunction,
    secondFunc: GenericFunction,
    ...otherFuncs: GenericFunction[]
  ]
) {
  return (...args: [arg: T, ...args: T[]]) => {
    return funcs.reduce(
      (acc: unknown, current) => current(acc),
      args.length > 1 ? args : args[0]
    )
  }
}

export function addTimeoutToPromise(
  asyncFunction: () => Promise<unknown>,
  timeoutInMilliseconds: number
) {
  return () =>
    new Promise((resolve, reject) => {
      asyncFunction().then((result) => resolve(result))
      setTimeout(() => {
        reject("TIMED_OUT")
      }, timeoutInMilliseconds)
    }) as Promise<unknown>
}

export function pause(milliseconds: number): Promise<void> {
  return new Promise((resolve, _) => {
    setTimeout(resolve, milliseconds)
  })
}

export function getRandomString(
  length: number,
  includeLetters = true,
  includeNumbers = true
): string {
  const chars = includeLetters
    ? "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
    : "" + includeNumbers
    ? "0123456789"
    : ""
  let randomString = ""
  for (let i = 1; i <= length; i++) {
    randomString += chars[Math.floor(Math.random() * chars.length)]
  }
  return randomString
}

export function saveTextToFile(content: string, filename: string) {
  const a = document.createElement("a")
  const file = new Blob([content], { type: "text/plain" })

  a.href = URL.createObjectURL(file)
  a.download = filename
  a.click()

  URL.revokeObjectURL(a.href)
}

const hexValues = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
]

export function rgbToHex(red: number, green: number, blue: number): string {
  const getHex = (n: number) => {
    const firstValue = hexValues[Math.floor(n / 16)]
    const secondValue = hexValues[n % 16]
    return `${firstValue}${secondValue}`
  }
  return `#${getHex(red)}${getHex(green)}${getHex(blue)}`
}

export function hexToRgb(hex: string): [number, number, number] {
  const redHex = hex.substring(0, 2)
  const greenHex = hex.substring(2, 4)
  const blueHex = hex.substring(4, 6)

  const getNumberForHexCharacter = (hexCharacter: string) =>
    hexValues.findIndex((x) => x === hexCharacter.toUpperCase())

  const getNumberForHexString = (hexString: string) =>
    getNumberForHexCharacter(hexString[0]) * 16 +
    getNumberForHexCharacter(hexString[1])

  return [
    getNumberForHexString(redHex),
    getNumberForHexString(greenHex),
    getNumberForHexString(blueHex),
  ]
}
