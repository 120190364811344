import { Dispatch, SetStateAction, useState } from "react"
import {
  Image,
  Modal,
  StyleSheet,
  Text,
  TextInput,
  ScrollView,
  View,
  TouchableOpacity,
} from "react-native"
import type { Card } from "../../lib/cards"
import { getCardImageFilename } from "../../lib/cards"
import ReactGA from "react-ga"
import { sendContactForm } from "../../lib/rules"
import YoutubePlayer from "react-native-youtube-iframe"
// eslint-disable-next-line @typescript-eslint/no-var-requires
const cardBackImage = require("../../../assets/cards/back.png")

const sampleHand: Card[] = [
  { suit: "Diamonds", value: "2" },
  { suit: "Hearts", value: "King" },
  { suit: "Spades", value: "Ace" },
  { suit: "Diamonds", value: "9" },
]
interface HowToPlayModalProps {
  isHowToPlayModal: boolean
  setIsHowToPlayModal: Dispatch<SetStateAction<boolean>>
}

ReactGA.initialize("UA-253764930-1")

export function HowToPlayModal({
  isHowToPlayModal,
  setIsHowToPlayModal,
}: HowToPlayModalProps) {
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false)
  const [isFeedbackBad, setIsFeedbackBad] = useState(false)
  const [message, setMessage] = useState("")
  const [isMessageSent, setIsMessageSent] = useState(false)

  const handleSendClick = () => {
    sendContactForm("how to play feedback", message)
      .then(() => {
        setIsMessageSent(true)
      })
      .catch(() => alert("Error sending message"))
  }

  return (
    <Modal
      animationType="fade"
      onRequestClose={() => setIsHowToPlayModal(false)}
      visible={isHowToPlayModal}
    >
      <ScrollView contentContainerStyle={howToModalStyles.container}>
        <View style={{ alignItems: "flex-end", marginBottom: 20, width: 420 }}>
          <TouchableOpacity
            onPress={() => setIsHowToPlayModal(false)}
            style={howToModalStyles.closeButton}
          >
            <Text
              style={{
                color: "#aaa",
                fontFamily: "Poppins",
                fontSize: 28,
              }}
            >
              ⓧ
            </Text>
          </TouchableOpacity>
        </View>
        <Text style={howToModalStyles.headerText}>How to Play</Text>
        <YoutubePlayer height={200} play={false} videoId={"blg5P2iJ8lk"} />

        <Text style={[howToModalStyles.instructionText, { marginTop: 0 }]}>
          1. Draw four cards
        </Text>
        <View style={howToModalStyles.handContainer}>
          {sampleHand.map((card) => (
            <Image
              source={require(`../../../assets/cards/${getCardImageFilename(
                card
              )}.png`)}
              style={howToModalStyles.card}
            />
          ))}
        </View>
        <Text style={howToModalStyles.instructionText}>
          2. Compare the first and fourth card
        </Text>
        <View style={howToModalStyles.handContainer}>
          <Image
            source={require(`../../../assets/cards/${getCardImageFilename(
              sampleHand[0]
            )}.png`)}
            style={howToModalStyles.card}
          />
          <Image
            source={require(`../../../assets/cards/dark_king_of_hearts.png`)}
            style={howToModalStyles.card}
          />
          <Image
            source={require(`../../../assets/cards/dark_ace_of_spades.png`)}
            style={howToModalStyles.card}
          />
          <Image
            source={require(`../../../assets/cards/${getCardImageFilename(
              sampleHand[3]
            )}.png`)}
            style={howToModalStyles.card}
          />
        </View>
        <Text style={howToModalStyles.instructionText}>
          3. If the suits match (i.e. both are diamonds), tap the middle two
          cards and tap 'Discard' to discard the middle two cards.
        </Text>
        <View style={howToModalStyles.handContainer}>
          <Image
            source={require(`../../../assets/cards/${getCardImageFilename(
              sampleHand[0]
            )}.png`)}
            style={howToModalStyles.card}
          />
          <Image
            source={require(`../../../assets/cards/${getCardImageFilename(
              sampleHand[1]
            )}.png`)}
            style={[howToModalStyles.card, { marginBottom: 20 }]}
          />
          <Image
            source={require(`../../../assets/cards/${getCardImageFilename(
              sampleHand[2]
            )}.png`)}
            style={[howToModalStyles.card, { marginBottom: 20 }]}
          />
          <Image
            source={require(`../../../assets/cards/${getCardImageFilename(
              sampleHand[3]
            )}.png`)}
            style={howToModalStyles.card}
          />
        </View>
        <View style={howToModalStyles.button}>
          <Text style={howToModalStyles.buttonText}>Discard</Text>
        </View>
        <Text style={howToModalStyles.instructionText}>
          4. If the values match (i.e. both are 2's), tap all four cards and tap
          'Discard' to discard all four cards.
        </Text>
        <View style={howToModalStyles.handContainer}>
          <Image
            source={require(`../../../assets/cards/${getCardImageFilename(
              sampleHand[0]
            )}.png`)}
            style={[howToModalStyles.card, { marginBottom: 20 }]}
          />
          <Image
            source={require(`../../../assets/cards/${getCardImageFilename(
              sampleHand[1]
            )}.png`)}
            style={[howToModalStyles.card, { marginBottom: 20 }]}
          />
          <Image
            source={require(`../../../assets/cards/${getCardImageFilename(
              sampleHand[2]
            )}.png`)}
            style={[howToModalStyles.card, { marginBottom: 20 }]}
          />
          <Image
            source={require(`../../../assets/cards/${getCardImageFilename({
              suit: "Clubs",
              value: "2",
            })}.png`)}
            style={[howToModalStyles.card, { marginBottom: 20 }]}
          />
        </View>
        <View style={howToModalStyles.button}>
          <Text style={howToModalStyles.buttonText}>Discard</Text>
        </View>
        <Text style={howToModalStyles.instructionText}>
          5. After discarding, tap the deck to draw another card until you have
          at least four cards in your hand and then repeat the process.
        </Text>
        <View
          style={{ flexDirection: "row", marginLeft: 150, marginBottom: 40 }}
        >
          <Image
            source={cardBackImage}
            style={[howToModalStyles.card, { marginLeft: -147 }]}
          />
          <Image
            source={cardBackImage}
            style={[howToModalStyles.card, { marginLeft: -147 }]}
          />
          <Image
            source={cardBackImage}
            style={[howToModalStyles.card, { marginLeft: -147 }]}
          />
          <Image
            source={cardBackImage}
            style={[howToModalStyles.card, { marginLeft: -147 }]}
          />
          <Image
            source={cardBackImage}
            style={[howToModalStyles.card, { marginLeft: -147 }]}
          />
        </View>
        <Text style={howToModalStyles.instructionText}>
          6. If neither the suits nor the values match, draw another card.
        </Text>
        <Text style={howToModalStyles.instructionText}>
          7. The goal is to discard as many cards as possible. Any remaining
          cards at the end of the game are called "splinters."
        </Text>
        <Text style={howToModalStyles.instructionText}>
          The lowest number of splinters in the lowest amount of time wins!
        </Text>
        {!isFeedbackSubmitted ? (
          <>
            <Text
              style={[
                howToModalStyles.instructionText,
                { textAlign: "center" },
              ]}
            >
              Was this helpful?
            </Text>
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-around",
                width: 150,
                marginBottom: 50,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  ReactGA.event({
                    category: "Feedback",
                    action: "Rated how to play",
                    value: 1,
                  })
                  setIsFeedbackSubmitted(true)
                }}
              >
                <Text style={{ fontSize: 34 }}>👍</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  ReactGA.event({
                    category: "Feedback",
                    action: "Rated how to play",
                    value: 2,
                  })
                  setIsFeedbackBad(true)
                  setIsFeedbackSubmitted(true)
                }}
              >
                <Text style={{ fontSize: 34, marginTop: 10 }}>👎</Text>
              </TouchableOpacity>
            </View>
          </>
        ) : (
          (!isFeedbackBad || isMessageSent) && (
            <Text
              style={[
                howToModalStyles.instructionText,
                { textAlign: "center" },
              ]}
            >
              Thanks for the feedback!
            </Text>
          )
        )}
        {isFeedbackBad && !isMessageSent && (
          <>
            <Text
              style={[
                howToModalStyles.instructionText,
                { textAlign: "center" },
              ]}
            >
              Tell us more...
            </Text>
            <TextInput
              multiline={true}
              numberOfLines={6}
              onChangeText={(text) => setMessage(text)}
              style={howToModalStyles.input}
              value={message}
            />
            <TouchableOpacity
              style={howToModalStyles.button}
              onPress={handleSendClick}
            >
              <Text style={howToModalStyles.buttonText}>Send</Text>
            </TouchableOpacity>
          </>
        )}
      </ScrollView>
    </Modal>
  )
}

const howToModalStyles = StyleSheet.create({
  button: {
    borderColor: "#999",
    borderRadius: 10,
    borderWidth: 1,
    color: "#eee",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 40,
    marginTop: 20,
    marginBottom: 40,
  },
  buttonText: {
    color: "#fefefe",
    fontFamily: "Archivo",
    fontSize: 22,
    fontWeight: "bold",
  },
  closeButton: {
    cursor: "pointer",
    paddingRight: 10,
  },
  container: {
    alignItems: "center",
    backgroundColor: "#111",
    justifyContent: "center",
    padding: 20,
  },
  handContainer: {
    alignItems: "flex-end",
    flexDirection: "row",
    marginLeft: 100,
    marginBottom: 40,
  },
  headerText: {
    color: "#eee",
    fontFamily: "Archivo",
    fontSize: 48,
    fontWeight: "700",
    letterSpacing: 1,
    marginBottom: 20,
  },
  input: {
    borderColor: "#ccc",
    color: "#eee",
    borderWidth: 1,
    fontFamily: "Poppins",
    fontSize: 16,
    outlineColor: "#000",
    padding: 10,
    width: 400,
  },
  instructionText: {
    color: "#eee",
    fontFamily: "Poppins",
    fontSize: 18,
    letterSpacing: 1,
    marginBottom: 20,
    marginTop: 20,
    maxWidth: 420,
    textAlign: "left",
  },
  card: {
    cursor: "pointer",
    height: 217,
    marginLeft: -100,
    width: 150,
  },
})
