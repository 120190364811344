import { Card, getCardImageFilename } from "../../lib/cards"
import { Image, StyleSheet, View } from "react-native"

interface DiscardedProps {
  cards: Card[]
}

const fullDeck = 52
const additionalSpacePerCard = 0.5
const deckHeightAdjuster = 3

export function Discarded({ cards }: DiscardedProps) {
  return (
    <View
      style={[
        discardedStyles.deckContainer,
        {
          marginLeft:
            125 + Math.floor(fullDeck - cards.length) * additionalSpacePerCard,
        },
      ]}
    >
      {new Array(Math.floor(cards.length / deckHeightAdjuster))
        .fill("")
        .map((_, i) => (
          <View key={`deck-card-${i}`} style={discardedStyles.cardContainer}>
            <Image
              source={require(`../../../assets/cards/${getCardImageFilename(
                cards[i]
              )}.png`)}
              style={discardedStyles.card}
            />
          </View>
        ))}
      {cards.length > 0 && (
        <View key={`deck-card-top`} style={discardedStyles.cardContainer}>
          <Image
            source={require(`../../../assets/cards/${getCardImageFilename(
              cards[cards.length - 1]
            )}.png`)}
            style={[discardedStyles.card]}
          />
        </View>
      )}
    </View>
  )
}

const discardedStyles = StyleSheet.create({
  card: {
    height: 181,
    width: 125,
  },
  cardContainer: {
    marginLeft: -123,
  },
  deckContainer: {
    flexDirection: "row",
  },
})
